import AssesmentCard from "./Components/AssesmentCard";
import { useTranslation } from "react-i18next";
const CheckService = () => {
  const { t } = useTranslation("translations");
  return (
    <div className="mdb:px-0 px-4 mt-2 mb-12 ">
      <div className="bg-main-violate-100 text-center max-w-max px-5 mdb:px-10 py-6 rounded-3xl mx-auto">
        <p className="text-xl mdb:text-3xl font-bold mb-4">
          {t("boost_up_candidate_title")}
        </p>
        <div className="flex justify-center mb-8">
          <p className="mdb:text-lg text-base max-w-[620px]">
            {t("boost_up_candidate_capt")}
          </p>
        </div>
        <div className="flex justify-center">
          <a
            className="bg-main-violate hover:bg-main-violate-900 text-base max-w-max px-6 py-4 text-base mdb:text-base text-white font-semibold tracking-normal  rounded-xl disabled:bg-violet-300 disabled:cursor-not-allowed text-center"
            type="submit"
            target="_blank"
            rel="noreferrer noopener"
            href={`https://www.linkedin.com/company/humata-indonesia/`}
          >
            Check Our Services
           </a>
        </div>
      </div>
    </div>
  );
};

export default CheckService;
