import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getArticle, getArticles, likeArticle, unlikeArticle } from "../../api";
import { ToastContainer, toast } from "react-toastify";
import PageSpinner from "../../Component/PageSpinner";
import moment from "moment";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import {
  ShareIcon,
  HandThumbUpIcon as LikedIcon,
} from "@heroicons/react/24/solid";
import { HandThumbUpIcon as LikeIcon } from "@heroicons/react/24/outline";

const DetailPage = ({}) => {
  const queryParams = new URLSearchParams(location.search);
  const tag = queryParams.get("tag") || null;
  const { slug } = useParams();
  const [article, setArticle] = useState({});
  const [relatedArticle, setRelatedArticle] = useState([]);
  const [loading, setLoading] = useState(true);
  const [relatedLoading, setRelatedLoading] = useState(true);
  const { language } = useSelector((state) => state.general);
  const fetchArticle = async () => {
    setLoading(true);
    const result = await getArticle(slug);
    if (result.success) {
      setArticle(result.data);
      fetchArticleByTopic(result.data.category.slug);
    } else {
      toast.error(result.message, {
        toastId: "login-error-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    }
    setLoading(false);
  };

  const fetchArticleByTopic = async (topic) => {
    setRelatedLoading(true);
    const result = await getArticles(language, topic, tag, 1, 10);
    if (result.success) {
      setRelatedArticle([...relatedArticle, ...result.data.data]);
    } else {
      toast.error(result.message, {
        toastId: "login-error-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    }
    setRelatedLoading(false);
  };

  const handleLike = async () => {
    setArticle({ ...article, liked: true, likes: article.likes + 1 });
    await likeArticle(article.slug);
  };

  const handleUnlike = async () => {
    setArticle({ ...article, liked: false, likes: article.likes - 1 });
    await unlikeArticle(article.slug);
  };

  useEffect(() => {
    fetchArticle();
  }, []);
  return (
    <div>
      <ToastContainer />
      <div className="flex flex-col items-center justify-center gap-16 bg-white w-full py-4">
        {loading && (
          <>
            <div className="h-[480px] py-4">
              <PageSpinner />
            </div>
          </>
        )}
        {!loading && (
          <>
            <Helmet>
              <meta charset="utf-8" />
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1"
              />
              <meta name="theme-color" content="#000000" />
              <meta name="description" content={article.description} />
              <meta name="keywords" content={article.keywords} />

              <meta property="og:type" content="website" />
              <meta
                property="og:url"
                content={`https://highr.id/articles/${article.slug}`}
              />
              <meta property="og:title" content={article.title} />
              <meta property="og:description" content={article.description} />
              <meta property="og:image" content="%PUBLIC_URL%/logo2_bg.png" />

              <meta property="twitter:card" content="summary_large_image" />
              <meta
                property="twitter:url"
                content={`https://highr.id/articles/${article.slug}`}
              />
              <meta property="twitter:title" content={article.title} />
              <meta
                property="twitter:description"
                content={article.description}
              />
              <meta property="twitter:image" content={article.image} />
              <link rel="apple-touch-icon" href={article.image} />
              <link rel="manifest" href={article.image} />
              <title>{article.title}</title>
            </Helmet>

            <div className="article-content w-full max-w-[720px] ">
              <div className="list-article-box w-full px-2 py-2 mt-4">
                <h1 className="md:text-[28px] text-[22px] font-bold">
                  {article.title}
                </h1>
                <span className="flex space-x-1 mb-1 mt-2">
                  <p className="text-[13px]">{article.author}</p>
                  <p className="text-[13px] text-gray-600">
                    · {moment(article.created_at).format("MMM D, YYYY")}
                  </p>
                </span>

                <hr className="mt-8 mb-8" />
                <div className="image-container h-full mb-8">
                  <img
                    src={article.image}
                    className="w-full h-full object-cover"
                    alt={article.caption}
                  />
                </div>
                {/* <p >{article.content}</p> */}
                <div
                  className="text-[16px] text-gray-600"
                  dangerouslySetInnerHTML={{ __html: article.content }}
                />
                <div className="flex mb-6 mt-[40px] flex-wrap ">
                  {article.tags?.map((tag) => (
                    <button className="bg-indigo-500 text-white mt-2 px-4 py-2 rounded-full text-md mr-2">
                      {tag}
                    </button>
                  ))}
                </div>
                <div className="flex text-sm items-center mb-4">
                  {article.liked ? (
                    <LikedIcon
                      className="cursor-pointer h-5 w-5 text-gray-500 mr-1 leading-0"
                      onClick={() => handleUnlike()}
                    />
                  ) : (
                    <LikeIcon
                      className="cursor-pointer h-5 w-5 text-gray-500 mr-1 leading-0"
                      onClick={() => handleLike()}
                    />
                  )}{" "}
                  {article.likes} Likes
                </div>
                <h2 className="md:text-[16px] text-[12px] font-bold mt-8">
                  Share this article
                </h2>
                <div className="flex gap-4 mt-4">
                  <button
                    className="flex items-center text-white rounded transition text-grey-500  text-xs flex-shrink-0 bg-line-grey rounded-full px-4 py-2"
                    onClick={() =>
                      window.open(
                        `https://www.facebook.com/sharer/sharer.php?u=${
                          "https://highr.id/articles/" + article.slug
                        }`,
                        "_blank"
                      )
                    }
                  >
                    <ShareIcon className="mr-2 flex-shrink-0 w-[20px]" />
                    Facebook
                  </button>
                  <button
                    className="flex items-center text-white rounded transition text-grey-500  text-xs flex-shrink-0 bg-line-grey rounded-full px-4 py-2"
                    onClick={() =>
                      window.open(
                        `https://twitter.com/intent/tweet?url=${
                          "https://highr.id/articles/" + article.slug
                        }&text=${article.title}`,
                        "_blank"
                      )
                    }
                  >
                    <ShareIcon className="mr-2 flex-shrink-0 w-[20px]" />
                    Twitter
                  </button>
                  <button
                    className="flex items-center text-white rounded transition text-grey-500  text-xs flex-shrink-0 bg-line-grey rounded-full px-4 py-2"
                    onClick={() =>
                      window.open(
                        `https://www.linkedin.com/shareArticle?mini=true&url=${
                          "https://highr.id/articles/" + article.slug
                        }&title=${article.title}`,
                        "_blank"
                      )
                    }
                  >
                    <ShareIcon className="mr-2 flex-shrink-0 w-[20px]" />
                    LinkedIn
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="related-container flex flex-col items-center justify-center gap-16 w-full py-6 bg-[#F9F9F9] ">
        <div className="related-content w-full max-w-[720px] ">
          <h2 className="md:text-[20px] text-[14px] font-bold px-2">
            Related Articles
          </h2>
          <div className="flex flex-col sm:flex-row flex-wrap">
            {relatedArticle
              ?.slice(0, 5)
              ?.filter((x) => x.title != article.title)
              .map((article) => (
                <div className="list-article-box w-full sm:w-[50%] px-2 py-2 border-b mt-4">
                  <a href={`/articles/${article.slug}`}>
                    <div className="image w-full mb-2">
                      {/* <div className="image-container h-full max-h-[90px] md:max-h-[120px]"> */}
                      <img
                        src={article.image}
                        className="w-full h-full object-cover"
                        alt={article.caption}
                      />
                      {/* </div> */}
                    </div>
                    <span className="flex space-x-1 mb-1">
                      <p className="text-[13px]">{article.author}</p>
                      <p className="text-[13px] text-gray-600">
                        · {moment(article.created_at).format("MMM D, YYYY")}
                      </p>
                    </span>
                    <div className="content flex min-h-[120px] md:space-x-6 space-x-5 mb-3 md:mb-6">
                      <div
                        className="text w-[70%] md:w-[80%] cursor-pointer"
                        // onClick={(e) => navigate(`/articles/${article.slug}`)}
                      >
                        <h1 className="text-[14px] mdb:text-[18px] md:text-[16px] font-bold limit-2">
                          {article.title}
                        </h1>

                        <p className="text-[14px] md:text-[16px] text-gray-600 limit-2 md:limit-3 mt-2">
                          {/* {truncateDescription(article.description, 30)} */}
                          {article.description}
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailPage;
